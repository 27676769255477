import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api/api.service';
import { environment } from 'environments/environment';
import { Professional } from 'app/model/professional';
import { EndpointsConstants } from 'app/shared/constants/endpoint.constants';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  constructor(private apiService: ApiService) {
  }

  get(page = 1, pageSize?: number, filters?: any, loader = true) {
    const url = EndpointsConstants.professionals.all;
    const params: any = {
      page: page,
      limit: pageSize
    }
    if (filters) {
      params.filters = JSON.stringify(filters);
    }

    return this.apiService.get(url, params, loader);
  }

  getOne(id) {
    return this.apiService.getOne(EndpointsConstants.professionals.all, id, true);
  }

  edit(professional: Professional, id) {
    const url = EndpointsConstants.professionals.single.replace('{id}', id);
    return this.apiService.put(url, professional, true);
  }

  add(professional: Professional) {
    return this.apiService.post(EndpointsConstants.professionals.all, professional, true);
  }

  delete(id) {
    const url = EndpointsConstants.professionals.single.replace('{id}', id);
    return this.apiService.delete(url);
  }
}
