export class User {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subscribed: boolean;
  password?: string;

  constructor(user) {
    if (user.id) { this.id = user.id; }
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.phone = user.phone;
    this.subscribed = user.subscribed;
    this.password = user.password;
  }
}
