import { Injectable } from '@angular/core';

import { Business } from 'app/model/business';
import { ApiService } from 'app/core/services/api/api.service';
import { EndpointsConstants } from 'app/shared/constants/endpoint.constants';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private baseUrl: string;

  constructor(private apiService: ApiService) { }

  get(page = 1, pageSize?: number, filters?: any, loader = true) {
    const params: any = {
      page: page,
      limit: pageSize
    }
    if (filters) {
      params.filters = JSON.stringify(filters);
    }

    return this.apiService.get(EndpointsConstants.businesses.all, params, loader);
  }

  getOne(id: string) {
    return this.apiService.getOne(EndpointsConstants.businesses.all, id, true);
  }

  edit(business: Business, id: string) {
    const url = EndpointsConstants.businesses.single.replace('{id}', id);
    return this.apiService.put(url, business, true);
  }

  add(business: Business) {
    return this.apiService.post(EndpointsConstants.businesses.all, business, true);
  }

  delete(id) {
    const url = EndpointsConstants.businesses.single.replace('{id}', id);
    return this.apiService.delete(url);
  }
}
