import { Injectable } from '@angular/core';

import { User } from 'app/model/user';
import { ApiService } from 'app/core/services/api/api.service';
import { EndpointsConstants } from 'app/shared/constants/endpoint.constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService) { }

  get(page?: number, pageSize?: number, filters?: any, loader = true) {
    const params: any = {
      page: page,
      limit: pageSize
    }
    if (filters) {
      params.filters = JSON.stringify(filters);
    }

    return this.apiService.get(EndpointsConstants.users.all, params, loader);
  }

  getOne(id) {
    return this.apiService.getOne(EndpointsConstants.users.all, id, true);
  }

  edit(user: User, id) {
    const url = EndpointsConstants.users.single.replace('{id}', id);
    return this.apiService.put(url, user, true);
  }

  add(user: User) {
    return this.apiService.post(EndpointsConstants.users.all, user, true);
  }

  delete(id) {
    const url = EndpointsConstants.users.single.replace('{id}', id);
    return this.apiService.delete(url);
  }
}
