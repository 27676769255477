import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api/api.service';
import { environment } from 'environments/environment';
import { Category } from 'app/model/category';
import { EndpointsConstants } from 'app/shared/constants/endpoint.constants';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private apiService: ApiService) {
  }

  get(page?: number, pageSize?: number, filters?: any) {
    const url = EndpointsConstants.categories.all;
    const params: any = {
      page: page,
      limit: pageSize
    }
    if (filters) {
      params.filters = JSON.stringify(filters);
    }

    return this.apiService.get(url, params, true);
  }

  getOne(id) {
    return this.apiService.getOne(EndpointsConstants.categories.all, id, true);
  }

  edit(category: Category, id) {
    const url = EndpointsConstants.categories.single.replace('{id}', id);
    return this.apiService.put(url, category, true);
  }

  add(category: Category) {
    return this.apiService.post(EndpointsConstants.categories.all, category, true);
  }

  delete(id) {
    const url = EndpointsConstants.categories.single.replace('{id}', id);
    return this.apiService.delete(url);
  }
}
