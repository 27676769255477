export const CatalogCouponsTypes = [
  {
    name: 'Cumpleaños',
    key: 'birthday'
  },
  {
    name: 'Un uso',
    key: 'one_use'
  },
  {
    name: 'Normal',
    key: 'normal'
  },
];

export const CatalogStatuses = [
  {
    name: 'Pendiente',
    key: 'pending'
  },
  {
    name: 'Cancelado',
    key: 'cancelled'
  },
  {
    name: 'Confirmado',
    key: 'confirmed'
  },
];
