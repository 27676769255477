export class Category {
  id?: number;
  name: string;
  description: string;
  icon: string;
  color: string;
  isActive: boolean;

  constructor(category) {
    if (category.id) { this.id = category.id; }
    this.name = category.name;
    this.description = category.description;
    this.icon = category.icon;
    this.color = category.color;
    this.isActive = category.isActive;
  }
}
